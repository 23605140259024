import { ajax } from "./utils";
import Message from "./Message";
import { updateTrackingInfos } from "./common/UpdateTrackingInfos";
import PaymentLoadingScreen from "./frontv2/Checkout/PaymentLoadingScreen";
import { canDefineAPassword, validatePasswordSetup } from "./frontv2/Checkout/checkout-utils";
import Drawer from "./common/Drawer";

const initStripe = () => {
  console.log('Stripe initialized');

  (async () => {
    'use strict';

    // Create references to the main form and its submit button.
    const form = document.getElementById('payment-form');
    const submitButton = form.querySelector('button[type=submit]');
    const checkouta = document.getElementById('checkouta');
    const formSelectCountry = form.querySelector('select[name=country]');

    // Retrieve the configuration for the store.
    const config = {
      // Default country for the checkout form.
      country: formSelectCountry.value,

      paymentRequestCountriesAvailable: [
        'AE', 'AT', 'AU', 'BE', 'BR', 'CA', 'CH', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HK', 'IE', 'IN', 'IT', 'JP', 'LT', 'LU', 'LV', 'MX', 'MY', 'NL', 'NO', 'NZ', 'PH', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'US'
      ],

      // Store currency.
      // currency: 'eur',
      currency: checkouta.dataset.currency ?? 'eur',

      // Supported payment methods for the store.
      // Some payment methods support only a subset of currencies.
      // Make sure to check the docs: https://stripe.com/docs/sources
      // paymentMethods: paymentMethodsSupported,
      // paymentMethods: [
      //   // 'ach_credit_transfer', // usd (ACH Credit Transfer payments must be in U.S. Dollars)
      //   'alipay', // aud, cad, eur, gbp, hkd, jpy, nzd, sgd, or usd.
      //   // 'bancontact', // eur (Bancontact must always use Euros)
      //   'card', // many (https://stripe.com/docs/currencies#presentment-currencies)
      //   // 'eps', // eur (EPS must always use Euros)
      //   // 'ideal', // eur (iDEAL must always use Euros)
      //   // 'giropay', // eur (Giropay must always use Euros)
      //   // 'multibanco', // eur (Multibanco must always use Euros)
      //   // 'sepa_debit', // Restricted. See docs for activation details: https://stripe.com/docs/sources/sepa-debit
      //   // 'sofort', // eur (SOFORT must always use Euros)
      //   'wechat', // aud, cad, eur, gbp, hkd, jpy, sgd, or usd.
      // ],

      // Configuration for Stripe.
      // API Keys: https://dashboard.stripe.com/account/apikeys
      // Webhooks: https://dashboard.stripe.com/account/webhooks
      // Storing these keys and secrets as environment variables is a good practice.
      // You can fill them in your own `.env` file.
      stripe: {
        // The two-letter country code of your Stripe account (required for Payment Request).
        country: 'FR',
        // API version to set for this app (Stripe otherwise uses your default account version).
        // apiVersion: '2019-03-14',
        // Use your test keys for development and live keys for real charges in production.
        // For non-card payments like iDEAL, live keys will redirect to real banking sites.
        publishableKey: checkouta.dataset.stripePublishKey,
        // secretKey: '',
        // Setting the webhook secret is good practice in order to verify signatures.
        // After creating a webhook, click to reveal details and find your signing secret.
        // webhookSecret: process.env.STRIPE_WEBHOOK_SECRET,
      },
    };

    // const createCustomerSession = (callback, intentId) => {
    //   const formData = new FormData();
    //   if(checkouta.dataset.hostId){
    //     const hostId = checkouta.dataset.hostId;
    //     formData.append('host_id', hostId);
    //   }

    //   formData.append('payment_intent_id', intentId);

    //   ajax(form.dataset.createCustomerSessionUrl, (data) => {
    //     if(data.success){
    //       callback(data);
    //     }
    //   }, 'POST', formData, {
    //     "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
    //   }, false, true);
    // }

    const getPaymentIntent = (callback) => {
      let xmlhttp = new XMLHttpRequest();

      xmlhttp.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
          if (xmlhttp.status === 200) {
            callback(JSON.parse(this.responseText));
          }
          else if (xmlhttp.status === 400) {
            console.error('There was an error 400');
          }
          else {
            console.error('something else other than 200 was returned');
          }
        }
      };

      const getParam = checkouta.hasAttribute('data-host-id') ? `?host_id=${checkouta.dataset.hostId}`
                                                              : '';

      xmlhttp.open("GET", checkouta.dataset.paymentIntentUrl + getParam, false);
      xmlhttp.setRequestHeader("X-CSRF-Token", document.querySelector('meta[name="csrfToken"]')?.content);
      xmlhttp.send();
    };

    let paymentIntent;
    await getPaymentIntent(function(data) {
      paymentIntent = data;
    });

    // let customerSessionClientSecret = null;
    // //create a customer session (to enable payment method save for later)
    // try{
    //   await createCustomerSession(data => customerSessionClientSecret = data.customer_session_client_secret, paymentIntent.id);
    // } catch(e){
    //   console.error(e);
    // }

    //init the loading screen
    let paymentLoadingScreen = new PaymentLoadingScreen();

    // Create a Stripe client.
    const stripe = Stripe(config.stripe.publishableKey);

    const appearance = {
      theme: 'stripe',
      variables: {
        borderRadius: '14px',
        colorPrimary: '#12312A',
      },
      labels: 'floating',
      rules: {
        '.Tab': {
          boxShadow: 'none',
        },
        '.Tab--selected': {
          boxShadow: 'none',
        },
        '.Tab--selected:focus': {
          boxShadow: 'none',
        },
        '.Tab:focus': {
          boxShadow: 'none',
          outline: 'none',
        },
        '.Input': {
          boxShadow: 'none',
        },
        '.Input:focus': {
          boxShadow: 'none',
        },
        '.Label': {
          color: '#9e9e9e',
        },
        '.Label--floating': {
          color: '#12312A',
          fontWeight: '600',
        },
      }
    };

    // Create an instance of Elements.
    let elementsOptions = {clientSecret: paymentIntent.client_secret, appearance};
    // if(customerSessionClientSecret != null){
    //   elementsOptions.customerSessionClientSecret = customerSessionClientSecret;
    // }

    const elements = stripe.elements(elementsOptions);

    const paymentElementOptions = {
      layout: {
        type: 'tabs',
      },
      fields: {
        billingDetails: {
          name: 'never',
          phone: 'never',
          email: 'never',
          address: 'if_required'
        },
      },
    };

    var paymentMethodChosed = '';
    const paymentElement = elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
    paymentElement.on('change', (event) => {
      paymentMethodChosed = event.value.type;
    });

    submitButton.disabled = true;
    paymentElement.on('ready', (event) => {
      submitButton.disabled = false;
    });

    /**
     * Handle the form submission.
     *
     * This uses Stripe.js to confirm the PaymentIntent using payment details collected with Elements.
     */

    // Submit handler for our payment form.
    form.addEventListener('submit', async event => {
      event.preventDefault();
      submitButton.disabled = true;

      //check if user decided to setup password and if so, check if passwords match
      if(!validatePasswordSetup(form)){
        submitButton.disabled = false;
        return false;
      }

      // check if user can define a password
      const setPasswordCheckbox = document.querySelector('#set-password');
      if(setPasswordCheckbox && setPasswordCheckbox.checked){
        let userCanDefineAPassword = await canDefineAPassword(form);
        if(!userCanDefineAPassword){
          Message.error(setPasswordCheckbox.dataset.cantDefineAPasswordErrorMessage);

          //open drawer so user can login
          window.setTimeout(() => {
            Drawer.openDrawer(document.querySelector('.login-drawer'));
          }, 500);

          submitButton.disabled = false;
          return false;
        }
      }

      if(!form.checkValidity()) {
        submitButton.disabled = false;
        return false;
      }

      //start the payment loading screen animation
      if(paymentLoadingScreen && paymentMethodChosed === 'card') paymentLoadingScreen.show();

      if(window.countdownInterval){
        clearInterval(window.countdownInterval);
      }

      //update cookies & gtm state to cart
      try{
        if(checkouta.hasAttribute('data-tracking-type')
            && checkouta.hasAttribute('data-tracking-cart-id')
            && checkouta.hasAttribute('data-tracking-update-infos-url'))
        {
          updateTrackingInfos(
            checkouta.dataset.trackingType,
            checkouta.dataset.transactionId,
            checkouta.dataset.trackingUpdateInfosUrl
          );
        }
      } catch(e){
        console.error(e);
      }

      //create a guest user if guest checkout
      if(checkouta.hasAttribute('data-guest-checkout')){
        await createGuestUser();
        // if(!createUser) return false;

        //if a discount code in guest checkout is applied, check all restrictions linked to the user
        const checkValidity = await checkUserRelatedDiscountValidity();
        if(!checkValidity){
          submitButton.disabled = false;
          if(paymentLoadingScreen && paymentMethodChosed === 'card') paymentLoadingScreen.hide();

          return false;
        }
      }
      else{
        //check if current user (organizer) is booking for someone else
        const organizerEmailInput = form.querySelector('input[name=organizer_email]');
        if(!organizerEmailInput){
          updateUserProfile();
        }
      }

      //associate transaction with data
      try{
        await associateTransactionData();
      }
      catch(e){
        console.error(e);
      }

      //hide the reserved spot countdown
      if(checkouta.hasAttribute('data-cart-id') && checkouta.dataset.cartId !== 'false'){
        const bookingHeldElt = document.getElementById('booking-held-wrapper');
        if(bookingHeldElt) bookingHeldElt.classList.add('hidden');
      }

      // Disable the Pay button to prevent multiple click events.
      submitButton.textContent = submitButton.hasAttribute('data-processing-text')
                                  ? submitButton.dataset.processingText
                                  : 'Processing...';

      updateBillingAddress();

      let name = '';
      let phone = '';
      let email = '';
      let country = null;

      //organizer infos if organizer is booking for someone else
      const organizerFirstNameInput = form.querySelector('input[name=organizer_firstname]');
      const organizerLastNameInput = form.querySelector('input[name=organizer_lastname]');
      const organizerPhoneInput = form.querySelector('input[name=organizer_phone]');
      const organizerEmailInput = form.querySelector('input[name=organizer_email]');
      if(organizerFirstNameInput && organizerLastNameInput && organizerPhoneInput && organizerEmailInput){
        name = organizerFirstNameInput.value + ' ' + organizerLastNameInput.value;
        phone = organizerPhoneInput.value;
        email = organizerEmailInput.value;
      }
      else{
        name = form.querySelector('input[name=firstname]')?.value + ' ' + form.querySelector('input[name=lastname]')?.value;
        phone = form.querySelector('input[name=phone]')?.value;
        email = form.querySelector('input[name=mail]')?.value;
        country = form.querySelector('select[name=country]')?.value;
      }

      const billingDetails = {
        name: name,
        phone: phone,
        email: email,
      };
      if(country){
        billingDetails.address = {
          country: country,
        };
      }

      //confirm the payment, if successful, redirects to the return_url URL
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: checkouta.dataset.thankYouUrl,
          payment_method_data: {
            billing_details: billingDetails
          }
        }
      });

      //this point is only reached if the payment is NOT successful
      if(paymentLoadingScreen && paymentMethodChosed === 'card') paymentLoadingScreen.hide();
      mainElement.classList.remove('processing');
      mainElement.classList.remove('receiver');
      mainElement.classList.add('error');

      const confirmationElement = document.getElementById('confirmation');
      if(confirmationElement){
        confirmationElement.querySelector('.error-message').innerText = error.message;
      }
    });

    const updateUserProfile = () => {
      let formData = new FormData();
      formData.append('phone', form.querySelector("input[name=phone]").value);
      formData.append('country_iso', form.querySelector("select[name=country]").value);
      formData.append('firstname', form.querySelector('input[name=firstname]').value);
      formData.append('lastname', form.querySelector('input[name=lastname]').value);

      //date of birth
      const dobDay = form.querySelector('input[name=dob_day]');
      const dobMonth = form.querySelector('input[name=dob_month]');
      const dobYear = form.querySelector('input[name=dob_year]');
      if(dobDay && dobMonth && dobYear){
        formData.append('birth_day', dobDay.value);
        formData.append('birth_month', dobMonth.value);
        formData.append('birth_year', dobYear.value);
      }

      ajax(form.dataset.updateProfile, () => {
        console.log('profil updated');
      }, 'POST', formData, {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      }, true, false);
    };

    const updateBillingAddress = () => {
      const billingForm = document.getElementById('billing-address-form');
      if(billingForm){
        let formData = new FormData();
        const billingCheckbox = billingForm.querySelector("input[name='billing-different']:checked");
        formData.append('billing-different', billingCheckbox ? 'no' : 'yes');
        formData.append('billing[firstname]', billingForm.querySelector("input[name='billing[firstname]']").value);
        formData.append('billing[lastname]', billingForm.querySelector("input[name='billing[lastname]']").value);
        formData.append('billing[address_line_1]', billingForm.querySelector("input[name='billing[address_line_1]']").value);
        formData.append('billing[address_line_2]', billingForm.querySelector("input[name='billing[address_line_2]']").value);
        formData.append('billing[zip]', billingForm.querySelector("input[name='billing[zip]']").value);
        formData.append('billing[city]', billingForm.querySelector("input[name='billing[city]']").value);
        formData.append('billing[country_id]', billingForm.querySelector("select[name='billing[country_id]']").value);
        formData.append('cart_id', billingForm.dataset.cartId);

        ajax(billingForm.dataset.url, () => {
          console.log('billing adress updated');
        }, 'POST', formData, {
          "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
        }, true, false);
      }
    }

    //if a discount code is applied, check all restrictions linked to the user
    const checkUserRelatedDiscountValidity = () => {
      return new Promise(resolve => {
        if(checkouta.hasAttribute('data-discount-code-for-check') && checkouta.dataset.discountCodeForCheck !== 'false'){
          let formData = new FormData();

          const emailInput = form.querySelector('input[name=mail]');
          const discountCode = checkouta.dataset.discountCodeForCheck;
          if(emailInput && discountCode){
            var email = emailInput.value;

            formData.append('email', email);
            formData.append('discountCode', discountCode);

            const initialButtonText = submitButton.innerHTML;
            submitButton.textContent = submitButton.dataset.checkingDiscountValidity;
            submitButton.disabled = true;
            ajax(form.dataset.checkUserRelatedDiscountValidityUrl, (data) => {
              if(!data.validity){
                Message.error(data.message);
                submitButton.disabled = false;
              }

              submitButton.innerHTML = initialButtonText;
              resolve(data.validity);
            }, 'POST', formData, {
              "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
            }, true, true);
          }
        }
        else{
          resolve(true);
        }
      });
    }

    //in case it is a guest checkout, create a guest user with all
    const createGuestUser = () => {
      return new Promise(resolve => {
        let formData = new FormData();

        const firstnameInput = form.querySelector('input[name=firstname]');
        const lastnameInput = form.querySelector('input[name=lastname]');
        const phoneInput = form.querySelector('input[name=phone]');
        const emailInput = form.querySelector('input[name=mail]');
        const countryInput = form.querySelector('select[name=country]');
        const langId = checkouta.dataset.langId;
        const origin = checkouta.dataset.type === 'booking' || checkouta.dataset.type === 'giftCard' ? 'website' : 'widget';
        if((firstnameInput && lastnameInput && phoneInput && emailInput && countryInput && langId && origin)){
          var firstname = firstnameInput.value;
          var lastname = lastnameInput.value;
          var phone = phoneInput.value;
          var email = emailInput.value;
          var country = countryInput.value;

          formData.append('firstname', firstname);
          formData.append('lastname', lastname);
          formData.append('phone', phone);
          formData.append('email', email);
          formData.append('country_iso', country);
          formData.append('langId', langId);
          formData.append('origin', origin);
          formData.append('stripeId', paymentIntent.id);
          if(checkouta.hasAttribute('data-host-id')){
            formData.append('hostId', checkouta.dataset.hostId);
          }

          if(checkouta.hasAttribute('data-transaction-id')){
            formData.append('transactionId', checkouta.dataset.transactionId);
          }

          //date of birth
          const dobDay = form.querySelector('input[name=dob_day]');
          const dobMonth = form.querySelector('input[name=dob_month]');
          const dobYear = form.querySelector('input[name=dob_year]');
          if(dobDay && dobMonth && dobYear){
            formData.append('birth_day', dobDay.value);
            formData.append('birth_month', dobMonth.value);
            formData.append('birth_year', dobYear.value);
          }

          const organizerEmailInput = form.querySelector('input[name=organizer_email]');
          if(organizerEmailInput){
            formData.append('organizer_is_booking', true);
          }

          const setPasswordCheckbox = form.querySelector('#set-password');
          const passwordInput = form.querySelector('input[name=password]');
          if(setPasswordCheckbox && setPasswordCheckbox.checked && passwordInput){
            formData.append('password', passwordInput.value);
          }

          const newsletter = form.querySelector('input[name=newsletter]');
          if(newsletter) formData.append('newsletter', newsletter.checked);

          const acceptsHostCommunication = form.querySelector('input[name=accepts_host_communication]');
          if(acceptsHostCommunication) formData.append('accepts_host_communication', acceptsHostCommunication.checked);

          submitButton.disabled = true;
          ajax(form.dataset.createGuestUserUrl, (data) => {
            resolve(data.success);
          },
          'POST', formData, {}, true, true);
        }
        else{
          resolve(false);
        }
      });
    }

    const associateTransactionData = () => {
      return new Promise(resolve => {
        let formData = new FormData();

        const emailInput = form.querySelector('input[name=mail]');
        const transactionId = checkouta.dataset.transactionId;
        const langId = checkouta.dataset.langId;
        if(emailInput && transactionId){
          var email = emailInput.value;

          formData.append('email', email);
          formData.append('transactionId', transactionId);
          formData.append('langId', langId);
          formData.append('stripeId', paymentIntent.id);
          if(checkouta.hasAttribute('data-host-id')){
            formData.append('hostId', checkouta.dataset.hostId);
          }

          //get organizer data if present and group them under an [organizer] key parent
          const organizerFirstNameInput = form.querySelector('input[name=organizer_firstname]');
          const organizerLastNameInput = form.querySelector('input[name=organizer_lastname]');
          const organizerPhoneInput = form.querySelector('input[name=organizer_phone]');
          const organizerEmailInput = form.querySelector('input[name=organizer_email]');
          const organizerSendBookingUpdates = form.querySelector('input[name=organizer_send_booking_updates]');
          if(organizerFirstNameInput && organizerLastNameInput && organizerPhoneInput && organizerEmailInput){
            formData.append('organizer[firstname]', organizerFirstNameInput.value);
            formData.append('organizer[lastname]', organizerLastNameInput.value);
            formData.append('organizer[phone]', organizerPhoneInput.value);
            formData.append('organizer[email]', organizerEmailInput.value);

            //get organizer data if present and group them under an [organizer] key parent
            if(organizerSendBookingUpdates && organizerSendBookingUpdates.checked){
              formData.append('organizer[send_booking_updates_to_organizer]', true);
            }
          }

          const newsletter = form.querySelector('input[name=newsletter]');
          if(newsletter) formData.append('newsletter', newsletter.checked);

          const acceptsHostCommunication = form.querySelector('input[name=accepts_host_communication]');
          if(acceptsHostCommunication) formData.append('accepts_host_communication', acceptsHostCommunication.checked);

          ajax(form.dataset.associateTransactionDataUrl, (data) => {
            resolve(data.success);
          },
          'POST', formData, {}, true, true);
        }
        else{
          resolve(false);
        }
      });
    }

    // bind retry to retry payment button
    const retry = () => {
      const mainElement = document.getElementById('main');
      if(mainElement){
        mainElement.classList.remove('error');
      }

      submitButton.textContent = submitButton.dataset.initialText;
      submitButton.disabled = false;
    };

    document.getElementById('retry-payment-button').addEventListener('click', retry);

    const mainElement = document.getElementById('main');
    // Update the interface to display the checkout form.
    mainElement.classList.add('checkout');

    document.getElementById('main').classList.remove('loading');
  })();
};

export {initStripe};
