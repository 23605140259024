import Message from "../../Message";
import { ajax, smoothScrollTo } from "../../utils";

// Google Ecommerce Analytics
const triggerConversion = (wrapper) => {
  if (typeof dataLayer != 'undefined') {
    dataLayer.push({
      'price': wrapper.dataset.humanAmount, // stripe payment amount
      'orderid': wrapper.dataset.transactionId, // stripe payment ID, not winalist order ID
      'stripe_id': wrapper.dataset.paymentIntentId, // stripe payment ID, not winalist order ID
      'eventLabel': wrapper.dataset.type,
    });

    //GA4 - Experience purchase
    try{
      const expAnalyticsBlock = document.querySelector('.experience-data');
      if(expAnalyticsBlock){
        //EETransaction event
        dataLayer.push({
          'event': 'EEtransaction',
          'eventLabel': expAnalyticsBlock.dataset.type,
          'ecommerce': {
            'currencyCode': expAnalyticsBlock.dataset.hostCurrency,
            'purchase': {
              'actionField': {
                'id': expAnalyticsBlock.dataset.paymentIntentId,
                'affiliation': window.location.hostname,
                'revenue': expAnalyticsBlock.dataset.humanAmount,
                'tax': '0.00',
                'shipping': '0.00'
              },
              'products': [{
                'name': expAnalyticsBlock.dataset.experienceName,
                'id': expAnalyticsBlock.dataset.experienceId,
                'price': expAnalyticsBlock.dataset.humanAmount,
                'brand': expAnalyticsBlock.dataset.hostName,
                'category': 'Experience',
                'quantity': 1
              }]
            }
          }
        });

        //experience purchase event
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: expAnalyticsBlock.dataset.paymentIntentId,
            currency: expAnalyticsBlock.dataset.hostCurrency,
            value: expAnalyticsBlock.dataset.humanAmount,
            items: [{
              item_id: expAnalyticsBlock.dataset.experienceId,
              item_name: expAnalyticsBlock.dataset.experienceName,
              currency: expAnalyticsBlock.dataset.hostCurrency,
              item_brand: expAnalyticsBlock.dataset.hostName,
              item_category: expAnalyticsBlock.dataset.categoryOne,
              item_category2: expAnalyticsBlock.dataset.categoryTwo,
              item_category3: expAnalyticsBlock.dataset.categoryThree,
              item_category4: expAnalyticsBlock.dataset.categoryFour,
              quantity: 1
            }]
          },
          buyer_email: expAnalyticsBlock.dataset.buyerEmail,
          buyer_phone: expAnalyticsBlock.dataset.buyerPhone,
          buyer_country: expAnalyticsBlock.dataset.buyerCountry,
          buyer_id: expAnalyticsBlock.dataset.buyerId,
          returning_user: expAnalyticsBlock.dataset.returningUser === 'true',
          newsletter: expAnalyticsBlock.dataset.newsletter === 'true'
        });

        //checkout_success event
        let dataObject = {
          'event': 'checkout_success',
          'category': 'page',
          'label': 'label_checkout_success',
          'buyer_email': expAnalyticsBlock.dataset.buyerEmail,
          'buyer_phone': expAnalyticsBlock.dataset.buyerPhone,
          'buyer_country': expAnalyticsBlock.dataset.buyerCountry,
          'buyer_id': expAnalyticsBlock.dataset.buyerId,
          'returning_user': expAnalyticsBlock.dataset.returningUser === 'true',
          'newsletter': expAnalyticsBlock.dataset.newsletter === 'true'
        };

        dataLayer.push(dataObject);

        console.log(dataLayer);
      }
    } catch(e){
      console.error(e);
    }

    //GA4 - GC purchase
    try{
      const gcAnalyticsBlock = document.querySelector('.gift-card-data');
      if(gcAnalyticsBlock){
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "purchase",
          ecommerce: {
            currency: 'EUR',
            value: gcAnalyticsBlock.dataset.amount,
            items: [{
              item_id: gcAnalyticsBlock.dataset.experienceId,
              item_name: gcAnalyticsBlock.dataset.type,
              currency: 'EUR',
              item_brand: gcAnalyticsBlock.dataset.hostName,
              item_category: gcAnalyticsBlock.dataset.categoryOne,
              item_category2: gcAnalyticsBlock.dataset.categoryTwo,
              item_category3: gcAnalyticsBlock.dataset.categoryThree,
              item_category4: gcAnalyticsBlock.dataset.categoryFour,
              quantity: 1
            }]
          }
        });
      }
    } catch(e){
      console.error(e);
    }
  }
}

//check if password pattern is valid
const checkPasswordPattern = (wrapper, passwordInput) => {
  let numberValid = false;
  let lengthValid = false;
  let symbolValid = false;

  const numberWrapper = wrapper.querySelector('[data-check-numbers]');
  const numberIcon = numberWrapper.querySelector("i");

  const lengthWrapper = wrapper.querySelector('[data-check-characters]');
  const lengthIcon = lengthWrapper.querySelector("i");

  const symbolWrapper = wrapper.querySelector('[data-check-symbols]');
  const symbolIcon = symbolWrapper.querySelector("i");

  ['keyup','change'].forEach( evt =>
    passwordInput.addEventListener(evt, () => {
      //Validate length
      if(passwordInput.value.length >= 8) {
        lengthWrapper.classList.remove("invalid");
        lengthIcon.classList.remove("icon-cross");
        passwordInput.classList.remove("invalid");

        lengthIcon.classList.add("icon-simple-check");
        lengthIcon.parentElement.classList.add("text-me-green-emerald");
        lengthIcon.parentElement.classList.remove("text-me-red");
        lengthValid = true;
      }
      else {
        lengthWrapper.classList.add("invalid");
        lengthIcon.classList.add("icon-cross");
        lengthIcon.classList.remove("icon-simple-check");
        passwordInput.classList.add("invalid");

        lengthIcon.parentElement.classList.add("text-me-red");
        lengthIcon.parentElement.classList.remove("text-me-green-emerald");
        lengthValid = false;
      }

      //Validate at least one number or one capital letter
      let numbers = /[0-9]/g;
      let oneCapitalLetter = /(.*[A-Z].*)/g;
      if(passwordInput.value.match(numbers) || passwordInput.value.match(oneCapitalLetter)) {
        numberWrapper.classList.remove("invalid");
        numberIcon.classList.add("icon-simple-check");
        numberIcon.parentElement.classList.add("text-me-green-emerald");

        passwordInput.classList.remove("invalid");
        numberIcon.parentElement.classList.remove("text-me-red");
        numberIcon.classList.remove("icon-cross");
        numberValid = true;
      }
      else {
        numberWrapper.classList.add("invalid");
        numberIcon.classList.remove("icon-simple-check");
        numberIcon.classList.add("icon-cross");
        passwordInput.classList.add("invalid");

        numberIcon.parentElement.classList.add("text-me-red");
        numberIcon.parentElement.classList.remove("text-me-green-emerald");
        numberValid = false;
      }

      //Validate at least one symbol
      let symbols = /[-!$%^&@#*()_+|~=`{}\[\]:";'<>?,.\/]/;
      if(passwordInput.value.match(symbols)) {
        symbolWrapper.classList.remove("invalid");
        symbolIcon.classList.add("icon-simple-check");
        symbolIcon.parentElement.classList.add("text-me-green-emerald");
        passwordInput.classList.remove("invalid");
        symbolIcon.parentElement.classList.remove("text-me-red");
        symbolIcon.classList.remove("icon-cross");
        symbolValid = true;
      }
      else {
        symbolWrapper.classList.add("invalid");
        symbolIcon.classList.remove("icon-simple-check");
        symbolIcon.classList.add("icon-cross");
        passwordInput.classList.add("invalid");

        symbolIcon.parentElement.classList.add("text-me-red");
        symbolIcon.parentElement.classList.remove("text-me-green-emerald");
        symbolValid = false;
      }

      return numberValid && lengthValid && symbolValid;
    }, false)
  );
}

//show/hide password inputs when user decides to setup password
const setupSetPasswordToggle = () => {
  const setPasswordCheckbox = document.querySelector('#set-password');
  const passwordInputs = document.querySelector('#password-inputs');
  const passwordInput = document.querySelector('input[name=password]');
  const repeatPasswordInput = document.querySelector('input[name=repeat-password]');
  if(setPasswordCheckbox && passwordInputs && passwordInput && repeatPasswordInput){
    setPasswordCheckbox.addEventListener('change', (event) => {
      passwordInputs.classList.toggle('hidden', !event.target.checked);

      passwordInput.required = event.target.checked;
      repeatPasswordInput.required = event.target.checked;
    });
  }
}

const validatePasswordSetup = (wrapper) => {
  //check if user decided to setup password
  const setPasswordCheckbox = wrapper.querySelector('#set-password');
  if(setPasswordCheckbox && setPasswordCheckbox.checked){
    //if user decided to setup password, check if passwords match
    const passwordInput = wrapper.querySelector('input[name=password]');
    const repeatPasswordInput = wrapper.querySelector('input[name=repeat-password]');
    if(passwordInput && repeatPasswordInput){
      //check if passwords match
      if((passwordInput.value !== repeatPasswordInput.value) || passwordInput.classList.contains('invalid')){
        let errorMessage = passwordInput.classList.contains('invalid') ? setPasswordCheckbox.dataset.invalidErrorMessage
                                                                       : setPasswordCheckbox.dataset.matchErrorMessage;

        //show error message
        Message.error(errorMessage);

        //smooth scroll to the invalid password inputs
        smoothScrollTo(setPasswordCheckbox);

        passwordInput.parentElement.classList.add('invalid');
        repeatPasswordInput.parentElement.classList.add('invalid');

        //remove invalid class after 3 seconds
        window.setTimeout(() => {
          passwordInput.parentElement.classList.remove('invalid');
          repeatPasswordInput.parentElement.classList.remove('invalid');
        }, 3000);

        return false;
      }
    }
  }

  return true;
}

const canDefineAPassword = (wrapper) => {
  return new Promise(resolve => {
    let formData = new FormData();

    const emailInput = wrapper.querySelector('input[name=mail]');
    const organizerEmailInput = wrapper.querySelector('input[name=organizer_email]');
    if(emailInput || organizerEmailInput){
      var email = organizerEmailInput ? organizerEmailInput.value : emailInput.value;

      formData.append('email', email);

      ajax(wrapper.dataset.canDefineAPasswordUrl, (data) => {
        resolve(data.canDefineAPassword);
      },
      'POST', formData, {}, true, true);
    }
    else{
      resolve(false);
    }
  });
}

export { triggerConversion, validatePasswordSetup, checkPasswordPattern, setupSetPasswordToggle, canDefineAPassword };
